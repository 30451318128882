import {h, VNode} from '../lib/dvdi';
import {pageHeader, pageFooter} from "../lib/page";
import {navigateEvent} from '../app';

export function humbugPage(): VNode {
    return h('div', {className: 'app'},
        pageHeader(),
        h('main', {className: 'main'},
            h('header', {className: 'banner'},
                h('div', {className: 'container'},
                    h('h1', {}, 'Humbug'),
                    h('p', {},
                        'An AI-first platform for building software'
                    )
                )
            ),
            h('main', {className: 'content'},
                h('div', {className: 'container'},
                    h('article', {},
                        h('section', {},
                            h('h2', {}, 'What is Humbug?'),
                            h('p', {},
                                'Humbug is an Open-Source AI-first platform designed to make it easier to build software. ' +
                                'It gives you an easy-to-use interface to interact with many different AIs at the same time, and ' +
                                'IDE-style file editing so you can stay in one environment as you do your work.'
                            ),
                            h('p', {},
                                'You can find out more on our ',
                                h('a', {
                                    href: 'https://youtube.com/@m6rai',
                                    target: '_blank',
                                    title: 'm6rai YouTube channel'
                                },
                                'YouTube'
                            ),
                            ' channel.'
                            )
                        ),
                        h('section', {},
                            h('h2', {}, 'Key Features'),
                            h('ul', {},
                                h('li', {}, 'Multi-AI interaction: Interact with multiple AI models simultaneously.'),
                                h('li', {}, 'IDE-style file editing: Edit files directly within the platform.'),
                                h('li', {}, 'AI-powered code generation: Leverage AI for code creation and assistance.'),
                                h('li', {},
                                    'Integration with Metaphor: Seamlessly integrate with the ',
                                    h('a', {
                                            href: '/metaphor',
                                            onclick: (e: MouseEvent) => navigateEvent(e, '/metaphor')
                                        },
                                        'Metaphor'
                                    ),
                                    ' language.')
                            )
                        ),
                        h('section', {},
                            h('h2', {}, 'Why Humbug?'),
                            h('p', {},
                                'Humbug is designed to address the challenges of modern software development by ' +
                                'integrating AI at its core.  It lets you be more productive and focus on the ' +
                                'creative aspects of software development, with AI expertise to support you.'
                            ),
                            h('p', {},
                                'The integration with Metaphor means you can rapidly construct AI assistants that will help you ' +
                                'with any aspect of your development.  Whether you need to get help on scoping a new product ' +
                                'capability, coding a major new feature, designing tests, or just building an chatbot that can answer ' +
                                'questions about your product, just code a Metaphor description of what you need and Humbug will ' +
                                'create an AI assistant to help!'
                            ),
                            h('figure', {},
                                h('img', {
                                    src: '/humbug/humbug-v0.2-2.webp',
                                    alt: 'Humbug hosting AI experts on its own design!',
                                    width: '1280',
                                    height: 'auto'
                                }),
                                h('figcaption', {}, 'Humbug hosting 2 AIs expert on its own design!')
                            ),
                            h('h2', {}, 'Open and Extensible'),
                            h('p', {},
                                'We believe in the power of using AI in a different way, so Humbug is open-source, and that ' +
                                'includes the Metaphor description we use to build and extend it.  By doing this we\'ve made ' +
                                'it easy to extend and our aim to to ensure it will always provide the best possible ' +
                                'experience when working with AI.'
                            ),
                            h('figure', {},
                                h('img', {
                                    src: '/humbug/humbug-v0.2-1.webp',
                                    alt: 'Humbug in use',
                                    width: '1280',
                                    height: 'auto'
                                }),
                                h('figcaption', {}, 'Humbug in action, with conversations and editing side-by-side')
                            )
                        ),
                        h('section', {},
                            h('h2', {}, 'Latest Release'),
                            h('p', {},
                                'The latest release of Humbug is available on GitHub: ',
                                h('a', {
                                        href: 'https://github.com/m6r-ai/humbug',
                                        target: '_blank',
                                        title: 'Humbug git repository on GitHub'
                                    },
                                    'github.com/m6r-ai/humbug'
                                )
                            )
                        ),
                        h('section', {},
                            h('h2', {}, 'Using Humbug with Metaphor'),
                            h('p', {},
                                'A tutorial showing how to use Metaphor within Humbug is available on GitHub: ',
                                h('a', {
                                        href: 'https://github.com/m6r-ai/hello-metaphor',
                                        target: '_blank',
                                        title: 'hello-metaphor git repository on GitHub'
                                    },
                                    'github.com/m6r-ai/hello-metaphor'
                                )
                            )
                        ),
                        h('section', {},
                            h('h2', {}, 'Join the Community'),
                            h('p', {},
                                'We invite you to explore Humbug and become part of our growing community. ' +
                                'Your feedback and contributions are valuable in shaping the future of AI-first software development.'
                            )
                        )
                    )
                )
            ),
            h('footer', {className: 'trailer'})
        ),
        pageFooter()
    );
}
